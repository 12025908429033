import React from 'react';
import logo from './logo.svg';
import Countdown from 'react-countdown-now';
import './App.css';

function App () {
  // Random component
  const Completionist = () => <span>0 0 0</span>;

  // Renderer callback with condition
  const renderer = ({days, hours, minutes, completed}) => {
    if (completed) {
      // Render a complete state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <div className="clockdown-container">
          <div className="clockdown-item2">{days}</div>
          <div className="clockdown-item2">{hours}</div>
          <div className="clockdown-item2">{minutes}</div>
        </div>
      );
    }
  };

  return (
    <div className="App container">
      <img src={logo} />
      <p className>JUZ 9 PAŹDZIERNIKA ZACZNIE SIĘ</p>
      <h1>SERIALOWY KONKURS KINO POLSKA!</h1>
      <div className="counter-container">
        <div className="clockdown-container">
          <div className="clockdown-item">DNI</div>
          <div className="clockdown-item">GODZIN</div>
          <div className="clockdown-item">MINUT</div>
        </div>
        <Countdown date={new Date (2019, 9, 10, 15)} renderer={renderer} />

      </div>
      <p>
        DO WYGRANIA PONAD
        {' '}
        <span>30 000 ZŁ</span>
        {' '}
        W GOTÓWCE <br /> I
        {' '}
        <span>63 NAGRODY RZECZOWE</span>
        {' '}
        NAJWYŻSZA <br /> NAGRODA PIENIĘŻNA TO AZ <span>10 000 ZŁ!</span>
      </p>

      <p>OGLĄDAJ SERIALOWE HITY PAŹDZIERNIKA W KINO POLSKA:</p>
      <p>
        <span>MISJA AFGANISTAN</span> <br />
        {' '}
        PREMIERA 12 PAŹDZIERNIKA O 22:05 <br />(CO TYDZIEŃ PO 2 ODCINKI)
      </p>

      <p>
        <span>KAPITAN SOWA NA TROPIE</span> <br />
        {' '}
        PREMIERA 13 PAŹDZIERNIKA O 16:55 <br />(CO TYDZIEŃ PO 2 ODCINKI)
      </p>

      <p className="bold">I WEŹ UDZIAŁ W ZABAWIE!</p>

      <p>
        <a href="https://www.facebook.com/KinoPolska/" target="_blank">
          OBSERWUJ NAS NA FACEBOOKU
        </a>
        {' '}
        I BĄDŹ GOTOWY NA WIELKIE SERIALOWE STARCIE!
      </p>
    </div>
  );
}

export default App;
